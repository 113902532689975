import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { API_URL } from "../constants";
import { UserCtx } from "../context/userContext";
import styles from "../style/SettingsUser.module.css";
import ChangePassword from "./ChangePassword";
import InputWindow from "./InputWindow";

interface SettingsUserProps {}

const SettingsUser: React.FC<SettingsUserProps> = ({}) => {
  const [userDiet, setUserDiet] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { user, setUser } = useContext(UserCtx);

  useEffect(() => {
    setUserDiet(user.diet);
  }, [user]);

  const dietClickHandler = async () => {
    try {
      const changeDiet = await axios.post(
        API_URL + "/users/diet",
        {
          diet: !user.diet,
        },
        { withCredentials: true }
      );

      setError(undefined);
      setUserDiet(!userDiet);
    } catch (error) {
      setError("Įvyko klaida");
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.changePassContainer}>
          <ChangePassword />
        </div>
        <div className={styles.dietContainer}>
          <InputWindow title="Dieta">
            <>
              <div className={styles.dietText}>
                {userDiet ? (
                  <div>
                    Jūsų vaikui darželyje <b>yra</b> taikomas
                    dietinis/veganiškas maitinimas.
                  </div>
                ) : (
                  <div>
                    Jūsų vaikui darželyje <b>nėra</b> taikomas
                    dietinis/veganiškas maitinimas.
                  </div>
                )}
              </div>
              <div className={styles.dietText}>
                {userDiet
                  ? "Ar pageitaujate darželyje taikyti įprastą maitinimą?"
                  : "Ar pageitaujate darželyje taikyti dietinį/veganišką maitinimą?"}
              </div>
              <div className={styles.dietBtnCont}>
                <button
                  onClick={() => dietClickHandler()}
                  className={styles.dietBtn}
                >
                  {userDiet
                    ? "Taikyti įprastą maitinimą"
                    : "Taikyti dietinį maitinimą"}
                </button>
                {error && <div className={styles.error}>{error}</div>}
              </div>
            </>
          </InputWindow>
        </div>
      </div>
    </>
  );
};

export default SettingsUser;
